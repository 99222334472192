#kale {
    #legalSurvey {
        /**
     Polaris sets z-index for table header in the range of 1000's
     Also inside AppLayout polaris sets z-index for tools window in the range of 850's
     Kale has a use-case to hide survey form when sidebar panel is opened.
     Currently we are using a overlay screen hide entire page but sidebar panel.
     In order to achieve this we need to reduce z-index for table header in the range of 500.
     Then we set overlay screen z-index in the range of 800 so that sidebar panel is editable.
     */
        .awsui-table-heading-container {
            z-index: 500 !important;
        }
    }

    .hidden {
        display: none;
    }
}
