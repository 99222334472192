@use "@amzn/awsui-design-tokens/polaris.scss" as awsui;

/* 
Currently, the API may send anchor tags in a response which are then rendered on the page.
Native anchor tags contradict the Polaris `Link (anchor component)` styles (i.e. different color,
text decoration, and hover behavior). As a result, we need to override all anchor tag styles.
*/
a {
    text-decoration: none;
    color: awsui.$color-text-link-default;
    &:hover {
        text-decoration: underline;
        color: awsui.$color-text-link-hover;
    }
}

div.section {
    &:first-of-type {
        margin-top: 30px;
    }

    margin-bottom: 30px;
    opacity: 1;

    &.hidden {
        opacity: 0;
    }

    &:not(.hidden) {
        animation-name: fadeIn;
        animation-duration: 1s;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

img {
    padding: 4px;
}
