#kale {
    #data-store-buttons {
        display: flex;
        padding-right: 5.5rem;
        justify-content: flex-end;
    }

    #level-of-detail-more-info-links {
        padding-top: 2rem;
    }

    #level-of-detail-pop-over {
        padding-bottom: 2rem;
    }

    .awsui-form-title {
        text-align: center;
    }

    .center-align {
        align-items: center;
        text-align: center;
        margin: auto;
    }

    .justify {
        .awsui-util-container-header {
            div {
                text-align: justify !important;
            }
        }
    }

    .justify-label {
        .awsui-form-field {
            label {
                text-align: justify;
            }
        }
    }

    awsui-form-field {
        .tooltip {
            font-style: italic;

            .awsui-form-field {
                opacity: 0.75;
            }
        }

        .awsui-form-field {
            margin-bottom: 10px;
        }
    }

    div.right-align {
        align-items: flex-end;
        text-align: right;
    }

    .normal-header {
        .awsui-util-container-header {
            font-weight: 400 !important;
        }
    }

    .dataStoreTableHeader {
        font-weight: bold;
    }

    #add-storage-div {
        padding-top: 35px;
    }

    #data-store-tech-label .awsui-form-field {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 1200px) {
    #kale {
        #data-store-tech-label .awsui-form-field {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
}

@media only screen and (max-width: 667px) {
    #kale {
        #data-store-tech-label .awsui-form-field {
            margin-top: 20px;
            margin-bottom: 30px;
        }
    }
}
