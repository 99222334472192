#kale {
    #questionThreeA {
        div.awsui-select-dropdown-options-container {
            // we need to use !important as polaris uses inline css which has highest priority
            max-height: 250px !important;
        }
    }

    .teamHeader {
        padding-left: 15px;
    }
}
